import React, { useState, useCallback, useRef } from 'react';
import styled from 'styled-components';
import { up } from 'styled-breakpoints';
import { Link as GatsbyLink, navigate } from 'gatsby';

import Button from './common/Button';
import SearchMenu from './SearchMenu';

import { useQuery } from '../context/queryContext';
import { useFilter } from '../context/filterContext';
import { useExpanded } from '../hooks/useExpanded';
import { useRandomPost } from '../hooks/useRandomPost';

const Container = styled.div`
  position: fixed;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  z-index: 2;
  display: none;

  ${up('tablet')} {
    display: block;
    padding-right: ${(props) => props.theme.pagePadding};
  }
`;

const Buttons = styled.div`
  * + * {
    margin-top: 1rem;
  }
`;

const SkinnyButton = styled(Button)`
  border-color: transparent;
  background-color: transparent;
  font-style: italic;
`;

const ResetButton = styled(SkinnyButton)`
  transition: opacity 0.3s;
  opacity: ${(props) => (props.visible ? 1 : 0)};
`;

const RandomButton = styled(Button)`
  transition: background-color 0.3s, color 0.3s;

  ${(props) =>
    props.theme.hover(`
    background-color: white;
    color: ${props.theme.colors.accent};
  `)}
`;

const Menu = ({
  isPostPage,
  onVorwortClick,
  onNachwortClick,
  hasScrolled,
  setTopText,
}) => {
  const [expanded, toggleExpanded] = useExpanded();
  const [focus, setFocus] = useState(null);
  const { query, setQuery } = useQuery();
  const goToRandomPost = useRandomPost();

  const {
    selectedCategory,
    selectedSort,
    selectedAuthor,
    setSelectedCategory,
    setSelectedSort,
    setSelectedAuthor,
  } = useFilter();

  const reset = useCallback(() => {
    scrollToTop();
    setSelectedCategory(null);
    setSelectedSort(null);
    setSelectedAuthor(null);
    setQuery(null);
    setTopText(false);
  }, [
    setSelectedCategory,
    setSelectedSort,
    setSelectedAuthor,
    setQuery,
    setTopText,
  ]);

  return (
    <Container>
      <Buttons>
        <ResetButton
          large
          onClick={reset}
          visible={
            query ||
            selectedAuthor ||
            selectedCategory ||
            selectedSort ||
            hasScrolled
          }
        >
          zurück
        </ResetButton>
        <SkinnyButton large dark onClick={onVorwortClick}>
          Vorwort
        </SkinnyButton>
        {isPostPage && (
          <Button large dark>
            <GatsbyLink to="/">Alle Einträge</GatsbyLink>
          </Button>
        )}
        {!isPostPage && (
          <>
            <Button
              large
              dark
              active={query}
              onClick={() => {
                setFocus('search');
                toggleExpanded();
              }}
            >
              Suche
            </Button>
            <Button
              large
              dark
              active={selectedAuthor}
              onClick={() => {
                setFocus('author');
                toggleExpanded();
              }}
            >
              Autor*innen
            </Button>
            <Button
              large
              dark
              active={selectedCategory}
              onClick={toggleExpanded}
            >
              Kapitelauswahl
            </Button>
            <Button large dark onClick={toggleExpanded} active={selectedSort}>
              Anordnung
            </Button>
          </>
        )}
        <RandomButton large dark onClick={goToRandomPost}>
          Random
        </RandomButton>
        <SkinnyButton large dark onClick={onNachwortClick}>
          Nachwort
        </SkinnyButton>
      </Buttons>
      {expanded && <SearchMenu toggleExpanded={toggleExpanded} focus={focus} />}
    </Container>
  );
};

function scrollToTop() {
  typeof window !== 'undefined' &&
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
}

export default Menu;
