import React, { useCallback } from 'react';
import styled, { createGlobalStyle, css } from 'styled-components';
import { navigate } from 'gatsby';

import { up } from 'styled-breakpoints';
import Post, {
  ShareButtonContainer,
  MoreButton,
  Container as PostContainer,
  ContainerOuter,
} from '../components/post/Post';
import { NavigationContainer } from '../components/Navigation';
import Menu from '../components/Menu';

const Container = styled.div`
  margin-top: 6rem;
  display: flex;
  flex-direction: column;

  ${up('tablet')} {
    margin-top: 12rem;
    padding-left: 3.2rem;
    padding-right: 12.2rem;
  }

  ${PostContainer} {
    min-height: 420px;
    transition: none;

    ${up('tablet')} {
      height: auto;
    }
  }
`;

const GlobalStyles = createGlobalStyle`
  ${ShareButtonContainer},
  ${MoreButton},
  ${NavigationContainer} {
    display: none;
  }

  ${ContainerOuter} {
    margin-bottom: 0;
    padding-bottom: 0;
    position: fixed;
    top: 0;
    left: 0;
  }

  ${PostContainer} {
    width: 780px;
  }

  ${Container} {
    margin-top: 0;
    padding-left: 0;
    padding-right: 0;
  }
`;

const PostPage = ({ location: { hash }, pageContext: { post, slug } }) => {
  const isOgImage = hash === '#ogImage';

  const onVorwortClick = useCallback(() => {
    navigate('/', {
      state: {
        vorwortNavigate: true,
      },
    });
  }, []);

  const onNachwortClick = useCallback(() => {
    navigate('/', {
      state: {
        nachwortNavigate: true,
      },
    });
  }, []);

  return (
    <>
      {isOgImage && <GlobalStyles />}
      <Container>
        <Menu
          isPostPage
          onVorwortClick={onVorwortClick}
          onNachwortClick={onNachwortClick}
        />
        <Post {...post} slug={slug} rightHandSide={false} isPostPage />
      </Container>
    </>
  );
};

export default PostPage;
